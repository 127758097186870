<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <div class="text-h5">
          It seems
        </div>
        <div class="text-h6 text-md-h5">
          You don't have an active subscription...
        </div>
        <div class="text-h3 text-md-h4 font-weight-bold">
          Get Started with New Year’s Resolutions using Visionbook
        </div>
        <div class="mt-4 plan">
          <v-img
            max-height="200"
            contain
            position="center center"
            alt="Visionbook"
            src="@/assets/plan/plan_nyr.png"
          />
        </div>
        <div class="mt-4 text-h5 font-weight-bold">
          New Year's Resolution Plan (SAVE 85%)
        </div>
        <div class="text-h6 font-weight-bold">
          $1.5 per month
          <span class="font-weight-regular">(billed anually at $18)</span>
        </div>
        <div class="mt-4">
          <BaseButton large @click="subscription">
            Get started
          </BaseButton>
        </div>
        <div class="mt-6 d-flex justify-space-between">
          <BaseButton
            :loading="loadingPortal"
            outlined
            color="gray"
            @click="billing"
          >
            {{ $t("payment.customer_portal") }}
          </BaseButton>
          <BaseButton outlined color="gray" @click="$emit('onLogout')">
            {{ $t("user.logout") }}
          </BaseButton>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { billingPortal } from "@/helpers/paymentHelpers";

export default {
  name: "UpgradeModal",
  data: () => ({
    loadingPortal: false,
    subscriptionsUrl: process.env.VUE_APP_VB_SUBSCRIPTIONS
  }),
  methods: {
    async billing() {
      this.loadingPortal = true;
      await billingPortal();
    },
    subscription() {
      window.location.assign("/payment?interval=year&interval_count=1&tp=nyr");
    }
  }
};
</script>

<style lang="scss" scoped>
.plan {
  min-height: 200px;
  margin: auto;
  border-radius: 20px;
}

@media (min-width: 960px) {
  .plan {
    width: 400px;
  }
}
</style>
